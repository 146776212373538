import React, { useState, useContext } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import Modal from "../common/Modal";
// import PlayBtn from "../../images/global/videoplay.svg";
import TagManager from 'react-gtm-module';

const Media = props => {

  	const { toggleModal, displayModal, setDisplayModal, scrollPosition } = useContext(ModalContext);
	const media = props.data;
	const bodyTag = document.getElementsByTagName('body')[0].classList;

	const [state, setState ] = useState({
		mediaType: '',
		id: '',
		caption: ''
	})

	const clearProps = (() => {
		setState({...state, mediaType: '', id: '', caption: ''});
		setDisplayModal(false);
		bodyTag.remove('no-scroll');
		window.scrollTo({top: scrollPosition, behavior: 'smooth'});
	});

	const handleMediaClick = ((id) => {
		TagManager.dataLayer({ dataLayer: { event: `Media btn - ${id}` } });
	});

	return (
		<>
			<section className={displayModal === true ? "mediaSection mediaHide" : "mediaSection"} ref={props.reference} id="mediaSection">
				<>
			 	<button className="btnDiscContainer" title="Disclaimers" onClick={(e)=> setState({...state, id:props.disclaimer, mediaType: "disclaimers", caption: "Disclaimers" }, toggleModal(e), handleMediaClick("Disclaimers"))}>
					<div className="discContainer">
						<p tabIndex="0">Disclaimers</p>
					</div>
				</button>

				<>
				{ props.award.length >= 2 ? 
				<div className="awardContainer">
					{
						props.award.map((index) => {
							return (
								<img src={`${index}`} key={index} alt="award" />
							)
						})
				}
				</div> : null
				}
				</>	
				
				{ 
					media.map((media, index)=> {
						return (
							<div className="mediaContainer" key={index} >
								
									<button title={`Open ${media.mediaType}`} onClick={(e)=> setState({...state, id:media.id, mediaType: media.mediaType, caption: media.title }, toggleModal(e), handleMediaClick(media.title[0]))}>
										<img className="imgBorder" src={media.poster} alt="" />
										{media.mediaType === "video" ? 
										<div className="btnCont" >
											<img className="playBtn" src={process.env.PUBLIC_URL + `/images/global/videoplay.svg`} 	alt="" />
											<p tabIndex="0"  dangerouslySetInnerHTML={{ __html:media.title }} />
											{/*<p tabIndex="0">{media.title[0]}</p>*/}
										</div> : 

										<div className="imageCaptionCont">
											<p tabIndex="0"  dangerouslySetInnerHTML={{ __html:media.title }} />
										</div>
										}
										
									</button>
								

							</div>
						)
					})
				 }

				</>
			</section>
			<Modal onClick={clearProps} type={state.mediaType} caption={state.caption} mediaId={state.id}/>
		</>
	)
}


export default Media;