import React, { useContext, useRef, useEffect} from "react";
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import { useHistory, useRouteMatch } from 'react-router-dom';
import Footer from "../common/Footer";
import CarMenu from "../common/CarMenu";
import OfferMenu from "../common/OfferMenu";
import Nav from "../common/Nav";
import DesktopQR from "../common/DesktopQR";

const Offers = props => {

	const history = useHistory();
	const { service } = useContext(KIAServicesContext);
	// const { name, data } = props;
	// new match variables
	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const inputElement = useRef(null);
	const firstFocus = useRef(null);

	const handleSubmit = (e) => {
		e.preventDefault();
		history.push(`/${name}/locate`);
	}

	useEffect(() => {
		// console.log("listened to service change", inputElement.current);
		inputElement.current.focus();
			return () => {
		}
	}, [service.offerType, service.modelId]);

	useEffect(() => {
		firstFocus.current.focus();
			return () => {

		}
	},[])

	return (
		<>
		<Nav />
		<div className="offerTitle">
			<span ref={firstFocus} tabIndex="0">Local offers for</span>
		</div>
		<CarMenu/>
		<OfferMenu/>
		<div className="offerContainer">
			<div>
				<p aria-hidden="true">For more offers visit</p>
				<a href="http://kia.com/offers" target="_blank" rel="noreferrer" tabIndex="0" title="For more offers visit kia.com/offers"><p>kia.com/offers</p></a>
			</div>
			<button ref={inputElement} className={service.offerType === '' || service.modelId === '' ? 'offerBtnDisable' : 'offerBtnActive'} disabled={service.offerType === '' || service.modelId === '' ? true : false} type="button" onClick={(e) => handleSubmit(e) } >
				View Offers
			</button>

		</div>
		<Footer />
		<DesktopQR />
		</>
	)
}


export default Offers;